import axios  from 'axios';
import {Howler} from 'howler';
import intralot from './intralot';
import nexlot from './nexlot';
import pariplay from './pariplay';
import neogames from './neogames';

export interface Frontend {
    code: string
    SendClientEvent: (eventName: string, gameId: string|null, languageCode: string, data?: any) => void
    ApplyLaunchParams: (game: EQLGame) => void
}

export interface EQLInfoMessage {
    title: string
    content: string
    quit?: boolean
}

export interface EQLGameState {
    config: any
    wrapperVersion: string
    gameVersion: string
    gameParams: any
    playMode: string // M (Money mode), D (Demo mode), H (History mode)
    accessibilityMode: string
    currencyCode: string
    currencySymbol: string
    languageCode: string
    adaMode: boolean
    ticketId: string | null
    playerId: string | null
    transactionId: string | null
    sessionToken: string | null
    promotionalGamesPending: boolean
    minimumBetInterval: number
    realityCheckInterval: number
    realityCheckRemaining: number
    realityCheckOpen: boolean
    balance: number
    bonusBalance: number
    wins: number
    totalWon: number
    cost: number
    totalCost: number
    sound: boolean
    helpOpen: boolean
    helpContent: string
    instantSettleResponse: any
    settleMessage: any
    rulesOpen: boolean
    rulesContent: string
    settingsOpen: boolean
    settingsConfig: any
    settings: any
    betId: string | null
    displaySize: {
        width: number
        height: number
    }
    wrapperSize: {
        width: number
        height: number
    }
    currentTime: Date
    showWrapper: boolean
    showReplay: boolean
    content: string | null
    extraData: string | null
    bonusRounds: any[]
    playCount: number
    wagerInProgess: boolean
}

interface Dimensions {
    width: number,
    height: number
}

export interface EQLGame {
    GAME_ID: string | null
    API_URL: string | null
    GAME_URL: string | null
    LOBBY_URL: string | null
    CLAIM_URL: string | null
    MONEY_MODE_URL: string | null
    DEPOSIT_URL: string | null
    CONTENT_URL: string | null
    PLATFORM: string | null
    STATE: EQLGameState
    FRONT_END: Frontend | null
    T: any
    I18N: any

    GetEnvironment: () => string
    GetCurrencyFormatter: () => Intl.NumberFormat
    GetBalance: () => number
    GetBonusBalance: () => number
    GetWins: () => number
    GetTotalWon: () => number
    GetCost: () => number
    GetTime: () => Date
    GetCurrencyCode: () => string
    GetCurrencySymbol: () => string
    GetPlayMode: () => string
    GetGameParams: () => any
    GetAccessibilityMode: () => string
    GetLanguageCode: () => string
    SetLanguageCode: (language: string) => void
    GetWrapperVersion: () => string
    GetGameVersion: () => string
    GetDisplaySize: () => {width: number, height: number}
    GetWrapperSize: () => {width: number, height: number}
    GetWrapperBarSize: () => {width: number, height: number}
    GetMinimumBetInterval: () => number
    GetPromotionalGamesPending: () => boolean
    GetSettings: () => any
    FormatCurrency: (amount: number) => string
    SaveGameState: (state: EQLGameState) => void 
    SetHelpContent: (helpContent: string) => void 
    SetRulesContent: (rulesContent: string) => void 
    SetSettingsConfig: (settingsConfig: string) => void 
    SetTotalWon: (totalWon: number) => void 
    SetWins: (wins: number) => void 
    SetCost: (cost: number) => void 
    SetBalance: (balance: number) => void 
    SetBetId: (betId: string) => void 
    SetBonusBalance: (balance: number) => void 
    SetGameParams: (gameParams: any) => void 
    LoadState: (wrapperState:EQLGameState) => void
    ShowHelp: () => void
    ShowRules: () => void
    ShowSettings: () => void
    CloseHelp: () =>  void
    CloseRules: () => void
    CloseSettings: () => void
    CloseRealityCheck: () => void
    GoToCashier: () => void
    ReturnToLobby: () => void
    ShowWrapper: () => void
    HideWrapper: () => void
    SetContent: (c:string) => void
    SetCurrentTime: (ct:Date) => void
    SetPlayMode: (c:string) => void
    SetBonusRounds: (c:any[]) => void
    GetBonusRounds: () => any[]
    IsADAMode: () => boolean
    ShowInfo: (i:EQLInfoMessage) => void
    ShowBonus: (i:EQLInfoMessage) => void
    ShowEndBonus: (i:EQLInfoMessage) => void
    ToggleSound: () => void
    SendClientEvent: (eventName:string, data?:any) => void
    UpdateSize: () => void
    Wager: (wager: number, callback: (result: any)=>void, numberOfBoards?: number, params?: any) => void
    Inquiry: (wagerId: string, callback: (response:any)=>void) => void
    Update: (transactionId: string, data: any, callback: (response:any)=>void) => void
    APIRequest: (method: string, endpoint: string, payload: any, callback: (response:any)=>void) => void
    Initialize: (loadContent: boolean) => void
    RestoreGameState: () => EQLGameState
    RegisterListener: (listernerName: string, eventName: string, callback: (d:any)=>any) => void
    SetWrapperSize: (size: Dimensions) => void
    SetDisplaySize: (size: Dimensions) => void
    SetI18n: (i18n: any, t: any) => void
    EventHandler: (eventData: any) => void
    GetMessage: (m: string) => string
    IncrementPlayCount: () => void
}

const initialState:EQLGameState = {
    config: {},
    wrapperVersion: "1.0.0",
    gameVersion: "1.0.0",
    gameParams: {},
    playMode: "D",
    accessibilityMode: "NONE",
    currencyCode: "",
    currencySymbol: "",
    languageCode: "",
    adaMode: false,
    promotionalGamesPending: false,
    minimumBetInterval: 2,
    balance: 0,
    bonusBalance: 0,
    bonusRounds: [],
    wins: 0,
    totalWon: 0,
    totalCost: 0,
    cost: 0,
    sound: false,
    helpOpen: false,
    helpContent: "",
    betId: null,
    ticketId: null,
    playerId: null,
    transactionId: null,
    sessionToken: null,
    realityCheckInterval: 5400,
    realityCheckRemaining: 5400,
    realityCheckOpen: false,
    rulesOpen: false,
    rulesContent: "",
    settingsOpen: false,
    settingsConfig: {},
    settings: {
      volume: 0,
    },
    displaySize: {
      width: 0,
      height: 0,
    },
    wrapperSize: {
      width: 0,
      height: 0,
    },
    currentTime: new Date(),
    showWrapper: true,
    showReplay: false,
    content: null,
    extraData: null,
    instantSettleResponse: null,
    settleMessage: null,
    playCount: 0,
    wagerInProgess: false
}
class EQL implements EQLGame {
    GAME_ID: string|null = null;
    API_URL: string|null = null;
    CONTENT_URL: string|null = null;
    STATE:EQLGameState = initialState;
    FRONT_END: Frontend|null = null;
    PLAYER_ID: string|null = null;
    GAME_URL: string|null = null;
    LOBBY_URL: string|null = null;
    DEPOSIT_URL: string|null = null;
    CLAIM_URL: string|null = null;
    MONEY_MODE_URL: string|null = null;
    PLATFORM: string|null = "W"; // W - Web, M - Mobile, H - History
    I18N: any;
    T: any;

    callbacks: {[eventName: string]:{[listernerName: string]:((c:any)=>any)}} = {};

    RegisterListener = (listernerName: string, eventName: string, callback: (d:any)=>any) => {
        if(!this.callbacks[eventName]) this.callbacks[eventName] = {};

        this.callbacks[eventName][listernerName] = callback;
    }

    GetCurrencyFormatter = ():Intl.NumberFormat => {
        return new Intl.NumberFormat(window.EQL.GetLanguageCode(), {
            style: 'currency',
            currency: window.EQL.GetCurrencyCode(),
        })
    }

    GetEnvironment = ():string => {
        if(this.API_URL?.indexOf(".dev.")!==-1 || this.API_URL?.indexOf(".rc.")!==-1) {
            return "dev";
        } else if(this.API_URL?.indexOf(".test.")) {
            return "test";
        }             
        
        return "prod";
    }

    GetConfig = ():any => {
        return this.STATE.config;
    }

    GetMessage = (m:string):string => {
        let message = this.T(m);
        if(this.STATE.config && this.STATE.config.messages && (m in this.STATE.config.messages)){
            message = this.STATE.config.messages[m];
        }

        return message;
    }

    GetBalance = ():number => {
        return this.STATE.balance;
    }

    GetBonusBalance = ():number => {
        return this.STATE.bonusBalance;
    }

    GetGameParams = ():any => {
        return this.STATE.gameParams;
    }

    GetTotalWon = ():number =>  {
        return this.STATE.totalWon;
    }

    GetWins = ():number =>  {
        return this.STATE.wins;
    }

    GetCost = ():number => {
        return this.STATE.cost;
    }

    GetTime = ():Date => {
        return this.STATE.currentTime;
    }

    GetCurrencyCode = ():string => {
        return this.STATE.currencyCode;
    }

    GetCurrencySymbol = ():string => {
        return this.STATE.currencySymbol;
    }

    GetPlayMode = ():string => {
        return this.STATE.playMode;
    }

    GetAccessibilityMode = ():string => {
        return this.STATE.accessibilityMode;
    }

    GetLanguageCode = ():string => {
        return this.STATE.languageCode;
    }

    SetLanguageCode = (language: string):void => {
        if(language==='ENU') language = 'en';

        this.STATE.languageCode = language;
        if(this.I18N) {
            this.I18N.changeLanguage(language);
        }
        this.callback('set_language', language);
    }

    SetBetId = (betId: string):void => {
        this.STATE.betId = betId;
        this.callback('set_betid', betId);
    }

    GetWrapperVersion = ():string => {
        return this.STATE.wrapperVersion;
    }

    GetGameVersion = ():string => {
        return this.STATE.gameVersion;
    }

    GetDisplaySize = ():{width: number, height: number} => {
        return this.STATE.displaySize;
    }

    GetWrapperSize = ():{width: number, height: number} => {
        return this.STATE.wrapperSize;
    }

    GetWrapperBarSize = ():{width: number, height: number} => {
        return this.STATE.wrapperSize;
    }

    GetMinimumBetInterval = ():number => {
        return this.STATE.minimumBetInterval;
    }

    GetPromotionalGamesPending = ():boolean => {
        return this.STATE.promotionalGamesPending;
    }

    GetSettings = ():any => {
        return this.STATE.settings;
    }

    FormatCurrency = (amount:number):string => {
        if(window.EQL.GetCurrencyCode() === "PEN") {
            return "S/ "+amount.toFixed(2);
        } else {
            return window.EQL.GetCurrencyFormatter().format(amount);
        }
    }

    SaveGameState = (state:EQLGameState):void => {
        window.localStorage.setItem('EQL_GAME_STATE', JSON.stringify(state));
        window.localStorage.setItem('EQL_WRAPPER_STATE', JSON.stringify(state));
    }

    LoadState = (wrapperState:EQLGameState):void => {
        let b = this.STATE.balance;
        this.STATE = wrapperState;
        this.STATE.realityCheckRemaining = this.STATE.realityCheckInterval;
        this.SetHelpContent(this.STATE.helpContent);
        this.SetRulesContent(this.STATE.rulesContent);
        this.SetSettingsConfig(this.STATE.settingsConfig);
        this.SetWins(0);
        this.SetTotalWon(0);
        this.SetBalance(b);
        this.SetBonusBalance(0);
        this.SetCost(0);
    }

    RestoreGameState = ():EQLGameState => {
        return this.STATE
    }

    SetI18n = (i18n: any, t: any):void => {
        this.T = t;
        this.I18N = i18n;
    }

    SetBonusRounds = (bonusRounds: any[]):void => {
        this.STATE.bonusRounds = bonusRounds;
        if(bonusRounds && bonusRounds.length>0 && bonusRounds[0].count>0 && this.GetPlayMode()==="M") {
            this.callback('set_bonus_round', bonusRounds[0]);
        } else {
            this.callback('set_bonus_round', null);
        }
    }

    GetBonusRounds = ():any[] => {
        return this.STATE.bonusRounds;
    }

    IncrementPlayCount = ():void =>  {
        this.STATE.playCount++;
    }

    IsADAMode = ():boolean => {
        return this.STATE.accessibilityMode!=='NONE';
    }

    Update = async (transactionId: string, value: any, callback: (response:any)=>void) => {
        try {
            let data = {transactionId: transactionId, value: value}
            await this.APIRequest("POST", "update", data, callback)
        } catch(err) {
            console.log(err)
        }
    }

    Wager = async (amount: number, callback: (result: any)=>void, count?: number, params?: any) => {
        if(this.STATE.wagerInProgess) {
            return;
        }

        // TODO: make this a configurable option as it may only apply to Michigan
        if(this.GetPlayMode()==="H" && this.STATE.playCount>0) {
            return;
        }

        this.STATE.wagerInProgess = true;
        this.IncrementPlayCount()
        let wager:any = {};
        wager.gameId = this.GAME_ID;
        wager.mode = this.STATE.playMode;
        wager.playerId = this.STATE.playerId;
        wager.currency = this.STATE.currencyCode; 

        if(params) {
            wager.params = JSON.stringify(params) 
        }      

        let bonusRound = false;

    

        if(this.GetPlayMode()==="M") {
            if(this.STATE.bonusRounds && this.STATE.bonusRounds.length>0) {
                if(this.STATE.bonusRounds[0].count>0) {
                    bonusRound = true;
                    wager.bonusId = this.STATE.bonusRounds[0].id;
                }
            }

            this.SendClientEvent(
                'roundStart', 
                {"totalBet": amount*(count?count:1)}
            );
        } else if(this.GetPlayMode()==="D") {
            const queryParams = new URLSearchParams(window.location.search)
            const scenarioId = queryParams.get("scenarioId");
            if(scenarioId) {
                wager.scenarioId = scenarioId;
            }
        }

        if(this.GetPlayMode()==="H") {
            wager.transactionId = this.STATE.transactionId;  
            if(!count || count<=0) count=1;         
            const totalBet = amount*count;
            let balance = this.STATE.gameParams.balance - totalBet;

            if(this.STATE.gameParams.tickets && 
                    this.STATE.gameParams.tickets.length>0 &&
                    this.STATE.gameParams.tickets[0].bonusId) {
                balance = this.STATE.gameParams.balance;
            }

            this.SetBalance(balance);
        } else {
            if(!count || count<=0) count=1;
            wager.count = count;
            wager.price = amount;
            const totalBet = amount*count;

            if(totalBet>=this.STATE.balance && this.GetPlayMode()==="D") {
                let config = this.GetConfig();
                this.SetBalance((config.demo.balance || 5000) +totalBet);
            }

            if(totalBet<=(this.STATE.balance+this.STATE.bonusBalance) ||
               (this.STATE.bonusRounds && this.STATE.bonusRounds.length>0 && this.GetPlayMode()==="M") ||
               this.FRONT_END?.code==="NEOGAMES") {

                if(this.STATE.bonusRounds && this.STATE.bonusRounds.length>0 && this.GetPlayMode()==="M") {
                    this.SetCost(0);
                } else {
                    this.SetCost(totalBet);
                }
                
                if(this.GetPlayMode()!=="M") {
                    this.SetBalance(this.STATE.balance - totalBet);
                }
            }  else {
                if(callback) {
                    callback({totalWin:0, tickets:[]});
                }
                //this.SendClientEvent('gameError'); // REMOVING THIS EVENT UNTIL THEY CHANGE THEIR MINDS ONCE AGAIN AND WANT IT BACK
                this.ShowInfo({title: "Balance", content: this.GetMessage("insuffient_funds")});

                return;
            } 
        }


        try {
            let r = await this.APIRequest("POST", "wager", wager, (res:any)=>{  
                this.STATE.instantSettleResponse = res.settleResponse;
                
                this.SendClientEvent(
                    "ticketReceived", 
                    {
                        "SessionData": {
                            "TotalBet": this.STATE.totalCost,
                            "TotalWin": this.STATE.totalWon
                        }, 
                         "Status":{ 
                            "ErrCode": res.code
                        }
                    }
                );

                if(res.code!==0 && res.code!=="0") {
                    if(res.code===900 || res.code==="900") {
                        this.SendClientEvent('gameError');
                    } else if(res.code===10 || res.code==="10" || res.code===18 || res.code==="18") {
                        this.ShowInfo({
                            quit: true,
                            title: "Gaming Limit", 
                            content: this.GetMessage("gaming_limit")})
                    } else if(res.code===180078 || res.code==="180078" || 
                              res.code===180088 || res.code==="180088" ||
                              res.code===180045 || res.code==="180045" || 
                              res.code===180044 || res.code==="180044" || 
                              res.code===180034 || res.code==="180034" || 
                              res.code===180035 || res.code==="180035" || 
                              res.code===180000 || res.code==="180000" || 
                              res.code===150006 || res.code==="150006") {
                        this.SendClientEvent('ShowContactUs')
                        this.SendClientEvent('gameError', {errorCode: res.code});
                    } else if(res.code===180082 || res.code==="180082") {
                        // TODO: localize as invalid bonus error
                        this.ShowInfo({title: "Error", content: "Something went wrong. General Server error"})
                    } else {
                        this.SendClientEvent('gameError', {errorCode: res.code});
                    }
                    return;
                } else {
                    if (this.STATE.playMode==="M" && ("balance" in res)) {
                        this.SendClientEvent(
                            'roundStarted', 
                            {"balanceBefore": this.GetBalance()}
                        );
                        this.SendClientEvent('gameRoundStarted');
                        this.SendClientEvent('balanceUpdated');

                        this.SetBalance(res.balance)
                        this.SetBonusBalance(res.bonusBalance)
                    }

                    if ("betId" in res) {
                        this.SetBetId(res.betId);
                    }                    

                    if(bonusRound && this.STATE.bonusRounds && this.STATE.bonusRounds.length>0) {
                        let brCount = this.STATE.bonusRounds[0].count-1; 

                        if(res.bonusInfo) {
                            if(res.bonusInfo.remainingPlays) {
                                brCount = res.bonusInfo.remainingPlays;
                            }

                            if(res.bonusInfo.playerBonusId) {
                                this.STATE.bonusRounds[0].id = res.bonusInfo.playerBonusId;
                            }
                        }

                        if (brCount<=0) {
                            this.STATE.bonusRounds.shift();
                        } else {
                            this.STATE.bonusRounds[0].count = brCount;
                        }

                        this.SetBonusRounds(this.STATE.bonusRounds)

                        if(brCount===0) {
                            this.STATE.settleMessage = {
                                title: "Free Games Complete", 
                                content: this.GetMessage("bonus_rounds_finished")};
                        }
                    }
                }

                if(callback) {
                    if(!res.totalWin) {
                        res.totalWin = 0;
                    }
                    callback(res)
                }
            })

            if(r===null && callback) {
                callback({totalWin:0, tickets:[]})
            }
        } catch(e:any) {
            this.STATE.wagerInProgess = false;
            if(callback) {
                callback({totalWin:0, tickets:[]})
            }

            this.SendClientEvent('gameError', {errorCode: 100000});

            if(e && e.response && e.response.data && e.response.data.error) {
                this.ShowInfo({title: "Error", content: e.response.data.error})
                console.log(e.message)
            } else {
                this.ShowInfo({title: "Error", content: this.GetMessage("general_error")})
            }
        }
    }

    Settle = async (tickets: any[], callback?: (result: any)=>void) => {
        this.STATE.wagerInProgess = false;
        if(this.GetPlayMode()==="D" && this.GetMessage("switch_to_money_mode") && this.GetMessage("switch_to_money_mode").length>0 && this.STATE.playCount%10===0) {
            this.callback('switch_to_money_mode', true);
        }

        if(this.STATE.settleMessage) {
            this.ShowEndBonus(this.STATE.settleMessage)
            this.STATE.settleMessage = null;
        }

        if(tickets && tickets.length>0) {
            if(this.STATE.playMode==="H") {
                let total = 0;
                let wins = 0;
                tickets.map((ticket:any) => {
                    let prize = parseInt(ticket.prizeAmount, 10)
                    if(prize && prize>0) {
                        wins = wins + 1;
                        this.SetTotalWon(total)
                        this.SetWins(wins)
                        this.SetBalance(this.STATE.balance + prize)
                    }
                    return prize
                })
            } else {
                try {                    
                    let processSettleResult = (res:any)=>{
                        let winAmount = 0;
                        tickets.map((ticket:any) => {
                            let prize = parseInt(ticket.prizeAmount, 10)
                            if(prize && prize>0) {
                                winAmount += prize;
                                // TODO: make this a configurable option for cumulative total won
                                if(this.FRONT_END?.code==="INTRALOT") {
                                    this.SetTotalWon(this.STATE.totalWon + prize)
                                } else {
                                    this.SetTotalWon(prize)
                                }
                                this.SetWins(this.STATE.wins + 1)

                                if(this.STATE.playMode==="D") {
                                    this.SetBalance(this.STATE.balance + prize)
                                }
                            }   
                            return prize
                        })

                        if(this.STATE.playMode==="M" && ("balance" in res)) {
                            this.SendClientEvent('roundEnded',{"winAmount": winAmount});
                            this.SendClientEvent('gameRoundEnded');
                            this.SendClientEvent('balanceUpdated');

                            if ((res.message 
                                 && res.message.length>0
                                 && res.message[0].displayType===3
                                 && res.message[0].text==="majorPrize")){
                                this.SendClientEvent('majorPrize'); 
                            } else if(res.majorPrize>0) {
                                this.SendClientEvent('majorPrize', {"value": res.majorPrize}); 
                            }
                                 
                            this.SetBalance(res.balance)
                            this.SetBonusBalance(res.bonusBalance)
                        }
                        if(callback) {
                            callback(res)
                        }
                    }

                    if(this.STATE.instantSettleResponse) {
                        processSettleResult(this.STATE.instantSettleResponse)
                    } else {
                        let transactionId = tickets[0].transactionId  
                        await this.APIRequest("POST", "settle", {transactionId: transactionId}, processSettleResult)  
                    }
                } catch(err) {
                    if(callback) {
                        callback({})
                    }
                    this.ShowInfo({title: "Error", content: this.GetMessage("general_error")})
                    console.log(err)
                }  
            }
        } else {
            if(callback) {
                callback({})
            }
        }
        this.STATE.instantSettleResponse = null;
        this.STATE.wagerInProgess = false;
    }

    Inquiry = async (wagerId: string, callback: (response:any)=>void) => {
        try {
            await this.APIRequest("POST", "inquiry", null, callback)
        } catch(err) {
            console.log(err)
        }
    }

    GetSession = async () => {
        let contentURL = 'https://'+window.location.hostname.replace("wrapper.","content.")+'/games/'

        if(window.location.hostname.indexOf("localhost")>=0) {
            contentURL = process.env.REACT_APP_CONTENT_URL || ''
        }

        const queryParams = new URLSearchParams(window.location.search)
        if(queryParams.get("IsNativeIos")==="true" && queryParams.get("nativeAppHostUrl")) {
            contentURL = queryParams.get("nativeAppHostUrl")+"/games/"
        }

        if(this.API_URL && contentURL) {
            let res = await this.APIRequest("POST", "session", null, ()=>{})

            if(res && res.data && res.data.instance) {
                this.STATE.config = res.data.config

                let br = res.data.bonusRounds

                let fgd = queryParams.get("FreeGameDetails") || queryParams.get("FreeGameDetailes")
                if(fgd) {
                    const fgdParts = fgd.split(",");
                    if(fgdParts.length===4) {
                        let bonusRound = {
                            id: fgdParts[0],
                            value: parseFloat(fgdParts[1])*100,
                            lines: parseInt(fgdParts[2], 10),
                            count: parseInt(fgdParts[3], 10),
                        }
                        br = [bonusRound]
                    }
                }  

                if(br && br.length>0 && this.GetPlayMode()!=="H") {
                    this.SetBonusRounds(br);

                    let formatter = new Intl.NumberFormat(
                        'en', {
                        style: 'currency',
                        currency: br[0].currency || res.data.instance.currency || 'USD',
                    })

                    let message = this.GetMessage("bonus_rounds_available")
                    message = message.replace("{bonusCount}", br[0].count)
                    message = message.replace("{bonusValue}", formatter.format(br[0].value/100*br[0].lines))

                    // TODO: make this a configurable option as it may only apply to Michigan
                    if (!this.IsADAMode()) {
                        this.ShowBonus({
                            title: "Free Game(s) Available", 
                            content: message})
                    }
                }

                if(res.data.playerId){
                    this.STATE.playerId = res.data.playerId;
                }

                if(!this.GetCurrencyCode()) {
                    this.STATE.currencyCode = res.data.instance.currency || 'USD';
                }

                if(!this.GetLanguageCode()) {
                    this.SetLanguageCode(res.data.instance.language || 'en');
                }

                if(this.STATE.playMode!=="D") {
                    if(res.data.bonusBalance) this.SetBonusBalance(res.data.bonusBalance)
                    this.SetBalance(res.data.balance)
                } else {
                    this.SetBonusBalance(0)
                    let config = this.GetConfig();
                    this.SetBalance(config.demo.balance || 5000)
                }

                let gamePath = res.data.instance.game.code
                if(res.data.instance.content_path_override) {
                    gamePath = res.data.instance.content_path_override
                }

                if(this.IsADAMode()) {
                    this.CONTENT_URL = contentURL + gamePath + '/ada.html'
                } else {
                    this.CONTENT_URL = contentURL + gamePath + '/index.html'
                }
                
                if(res.data.instance.operator && res.data.instance.operator.front_end) {
                    if(res.data.instance.operator.front_end==="INTRALOT") {
                        this.FRONT_END = new intralot();
                    } else if(res.data.instance.operator.front_end==="NEXLOT") {
                        this.FRONT_END = new nexlot();
                    } else if(res.data.instance.operator.front_end==="PARIPLAY") {
                        this.FRONT_END = new pariplay();
                    } else if(res.data.instance.operator.front_end==="NEOGAMES") {
                        this.FRONT_END = new neogames();
                    }
                }

                if(this.STATE.playMode==="H" && res.data.params) {
                    this.SetGameParams(res.data.params);
                    
                    if(res.data.params.tickets && res.data.params.tickets.length>0) {                        
                        this.SetCurrentTime(new Date(res.data.params.tickets[0].issuedTime*1000));
                        this.SetBetId(res.data.params.tickets[0].info?.betId);
                        this.SetCost(res.data.params.tickets[0].price*res.data.params.tickets.length);
                    } else {
                        this.SetCurrentTime(new Date());
                    }
                }
            } else {
                this.SetLanguageCode(this.STATE.languageCode || 'en');
                this.ShowInfo({title: "Error", content: this.GetMessage("session_error")});
                this.SetBalance(0);
            }
            
            if(this.STATE.playMode!=="H") {
                this.SetCost(0);
            } else {
                this.ShowReplay();
            }
        } 
    }


    APIRequest = async (method: string, endpoint: string, payload: any, callback: (response:any)=>void):Promise<any> => {
        if(this.GetConfig().realityCheck?.mode==="idle") {
            this.STATE.realityCheckRemaining=this.STATE.realityCheckInterval; 
        }
        
        if(this.API_URL) {
            if(!payload) {
                payload = {}
            }

            let headers:any = {}
            if(this.STATE.sessionToken) {
                headers.Authorization = "Bearer "+this.STATE.sessionToken
            }

            if(this.STATE.playerId) {
                payload["playerId"] = this.STATE.playerId
            }

            if(this.STATE.ticketId) {
                payload["ticketId"] = this.STATE.ticketId
            }

            if(this.STATE.transactionId) {
                payload["transactionId"] = this.STATE.transactionId
            }

            if(this.GAME_ID) {
                payload["gameId"] = this.GAME_ID
            }

            if(this.STATE.playMode) {
                payload["mode"] = this.STATE.playMode
            }

            if(this.STATE.extraData) {
                payload["extraData"] = this.STATE.extraData
            }

            if(this.STATE.betId) {
                payload["walletTransactionId"] = this.STATE.betId
            }

            try {
                let res = await axios({
                    method: method,
                    url: window.EQL.API_URL+"/"+endpoint,
                    data: payload,
                    headers: headers,
                })
                if(callback && res) {
                    callback(res.data)
                }
                
                return res
            }
            catch(e:any) {
                if(e && e.response && e.response.data && e.response.data.error) {
                    this.ShowInfo({title: "Error", content: e.response.data.error})
                    console.log(e.message)
                } else {
                    this.ShowInfo({title: "Error", content: this.GetMessage("general_error")})
                }
                return null
            }
        } 

        return null
    }

    EventHandler = (eventData:any) =>  { 
        switch(eventData.data.type) { 
            case "stopAutobet": this.callback('stopAutobet',true); break; 
            case "pause": this.callback('pause',true); break; 
            case "resume": this.callback('resume',true); break; 
            case "updateBalance": {
                console.log("updating balance: "+JSON.stringify(eventData.data));
                if(eventData.data?.data?.value) {
                    this.SetBalance(eventData.data.data.value*100); 
                }
                break; 
            }
        } 
    }

    Initialize = async (playModeSwitch: boolean) => {
        this.SendClientEvent('onAppFrameReady'); 

        window.addEventListener('message', this.EventHandler, false);

        this.API_URL = 'https://'+window.location.hostname.replace("wrapper.","api.")+'/v1'

        if(window.location.hostname.indexOf("localhost")>=0) {
            this.API_URL = process.env.REACT_APP_API_URL || ''
        }
        
        const queryParams = new URLSearchParams(window.location.search)
        const gameId = queryParams.get("gameId") || queryParams.get("gameCode") || queryParams.get("GameID")
        this.GAME_ID = gameId

        let rgsUrl = 
            queryParams.get("rgsUrl") || 
            queryParams.get("RgsUrl") || 
            queryParams.get("RGSUrl") ||
            queryParams.get("remoteOrigin") ||
            queryParams.get("remoteorigin") ||
            queryParams.get("RemoteOrigin")
            

        if(rgsUrl) {
            rgsUrl = rgsUrl.replace(/(https:\/\/[^\/]+).*/, '$1');
            rgsUrl = rgsUrl.replace("game.", "api.")+'/v1';
            this.API_URL = rgsUrl
        }

        const sessionToken = queryParams.get("sessionToken") || queryParams.get("token") || queryParams.get("SessionToken")
        this.STATE.sessionToken = sessionToken

        if(!playModeSwitch) {
            const isReal = queryParams.get("isReal")
            if(isReal==="true") {
                this.STATE.playMode = "M"
            } else if(isReal==="false") {
                this.STATE.playMode = "D"
            } else {
                const playMode = queryParams.get("playMode") || queryParams.get("PlayMode")
                this.STATE.playMode = playMode || "D"
            }
        }

        const isADA = (queryParams.get("ADA") || queryParams.get("ada")) ?? "";
        const isADALower = isADA.trim().toLowerCase();
        this.STATE.accessibilityMode = ['1', 'true', 'True', 'TRUE'].includes(isADALower) ? "ADA" : "NONE"

        const transactionId = queryParams.get("transactionId") || queryParams.get("GameInstanceID")
        this.STATE.transactionId = transactionId

        const playerId = queryParams.get("playerId") || queryParams.get("PlayerID")
        this.STATE.playerId = playerId

        const language = queryParams.get("language") || queryParams.get("languageCode") || queryParams.get("Language")
        if(language && language.length>=2) {
            this.SetLanguageCode(language.substring(0,2).toLowerCase())
        }

        const currency = queryParams.get("currency") || queryParams.get("currencyCode") || queryParams.get("Currency")
        if(currency) {
            this.STATE.currencyCode = currency;
        }

        this.STATE.betId = queryParams.get("GameInstanceID")

        await this.GetSession()

        this.SendClientEvent(
            "gameDataLoaded", 
            {"LoadGameData": 
                { "SessionData": { 
                        "TotalBet": 0, 
                        "TotalWin": 0, 
                        "SessionBalance": this.GetBalance() 
                    }
                }
            });

        this.SendClientEvent('gameLoaded'); 

        if(this.CONTENT_URL && !playModeSwitch) {
            if(this.FRONT_END) {
                console.log("applying launch parameters")
                let game:any = this.FRONT_END.ApplyLaunchParams(this)
                Object.keys(game).forEach(key=>(this as any)[key]=game[key]);
            }

            window.addEventListener("resize", window.EQL.UpdateSize);
        
            if(this.SetContent && !this.STATE.content) {
                try {
                    let contentRes = await axios.get(this.CONTENT_URL)
                    this.SetContent(contentRes.data)
                    this.SetLanguageCode(this.STATE.languageCode)
                    window.EQL.UpdateSize()
                    this.SendClientEvent("gameReady");
                } catch(err) {
                    this.ShowInfo({title: 'Error', content: this.GetMessage("general_error")})
                }      
            }

            window.EQL.STATE.realityCheckRemaining = window.EQL.STATE.realityCheckInterval;

            if(this.GetPlayMode()!=="H") {
                setInterval(() => {
                    window.EQL.SetCurrentTime(new Date())
                    window.EQL.STATE.realityCheckRemaining--;

                    if(window.EQL.STATE.realityCheckRemaining<=0 &&
                        window.EQL.STATE.realityCheckInterval>0 && 
                        !window.EQL.STATE.realityCheckOpen) {
                        this.ShowRealityCheck();
                    }
                }, 1000);
            }
        }
    }

    callback = (eventName: string, value: any) => {
        if(this.callbacks[eventName]) {
            Object.keys(this.callbacks[eventName]).map((listernerName) => 
                this.callbacks[eventName][listernerName](value));
        }
    }

    ShowHelp = () => {this.STATE.helpOpen=true; this.callback('help_open',true)}
    ShowRules = () => {this.STATE.rulesOpen=true; this.callback('rules_open',true)}
    ShowSettings = () => {this.STATE.settingsOpen=true; this.callback('settings_open',true)}
    ShowRealityCheck = () => {this.STATE.realityCheckOpen=true; this.callback('realitycheck_open',true)}
    CloseHelp = () => {this.STATE.helpOpen=false; this.callback('help_open',false)}
    CloseRules = () => {this.STATE.rulesOpen=false; this.callback('rules_open',false)}
    CloseSettings = () => {this.STATE.settingsOpen=false; this.callback('settings_open',false)}
    CloseRealityCheck = () => {
        this.STATE.realityCheckOpen=false; 
        this.STATE.realityCheckRemaining=this.STATE.realityCheckInterval; 
        this.callback('realitycheck_open',false)
    }
    ReturnToLobby = () => {
        this.callback('lobby', null);

        if(this.FRONT_END?.code==='PARIPLAY' || this.FRONT_END?.code==='NEOGAMES') {
            if(this.STATE.realityCheckOpen) {
                this.SendClientEvent('LogoutSanityCheck'); 
                this.CloseRealityCheck()
            } else {
                this.SendClientEvent('closeGame');
                this.SendClientEvent('quit');
                this.SendClientEvent('ReturnToLobby'); 
                this.SendClientEvent('backToLobby');
            }
        } else {
            this.CloseRealityCheck()
            if(this.LOBBY_URL) {
                if(this.PLATFORM==="W") {
                    window.parent.postMessage('openLobby','*')
                }
                if(window.top && window.top.location) {
                    window.top.location.href = this.LOBBY_URL
                }
                else {
                    window.location.href = this.LOBBY_URL
                }
            } 
        }
    }
    GoToCashier = () => {
        this.callback('cashier', null);
        if(this.FRONT_END?.code==='PARIPLAY') {
            this.SendClientEvent(
                'cashier', 
                { "reason": "Ribbon", "target": this.DEPOSIT_URL }); 
            this.CloseRealityCheck()
        } if(this.FRONT_END?.code==='NEOGAMES') {
            this.SendClientEvent('cashier'); 
        } else if(this.DEPOSIT_URL) {
            if(this.PLATFORM==="W") {
                window.parent.postMessage('openCashier','*')
            }
            if(window.top && window.top.location) {
                window.top.location.href = this.DEPOSIT_URL
            }
            else {
                window.location.href = this.DEPOSIT_URL
            }
        }
    }
    SetHelpContent = (c:string) => {this.STATE.helpContent=c; this.callback('help_content',c)}
    SetRulesContent = (c:string) => {this.STATE.rulesContent=c; this.callback('rules_content',c)}
    SetSettingsConfig = (c:any) => {this.STATE.settingsConfig=c; this.callback('settings_config',c)}
    SetWins = (c:number) => {this.STATE.wins=c; this.callback('set_wins',c);}
    SetTotalWon = (c:number) => {this.STATE.totalWon=c; this.callback('set_total_won',c);}
    SetCost = (c:number) => {this.STATE.cost=c; this.STATE.totalCost+=c;  this.callback('set_cost',c);}
    SetBalance = (c:number) => {
        if(!c || Number.isNaN(c)) {
            this.STATE.balance=0;
            this.SendClientEvent("balance", {"amount": 0});
            this.callback('set_balance',0);
        } else {
            this.STATE.balance=c;
            this.SendClientEvent("balance", {"amount": c});
            this.callback('set_balance',c);

            if(this.STATE.balance<=0 && this.DEPOSIT_URL) {
                this.GoToCashier()
            }
        }
    }
    SetBonusBalance = (c:number) => {
        if(!c || Number.isNaN(c)) {
            this.STATE.bonusBalance=0;
            this.callback('set_bonus_balance',0);
        } else {
            this.STATE.bonusBalance=c;
            this.callback('set_bonus_balance',c);
        } 
    }
    SetGameParams = (gp:any) => {
        this.STATE.gameParams=gp;
        this.callback('set_game_params',gp);
    }
    ShowWrapper = () => {this.STATE.showWrapper=true; this.callback('show_wrapper',true);}
    HideWrapper = () => {this.STATE.showWrapper=false; this.callback('show_wrapper',false);}
    ShowReplay = () => {this.STATE.showReplay=true; this.callback('show_replay',true);}
    SetContent = (c:string) => {this.STATE.content=c; this.callback('set_content',c);}
    SetCurrentTime = (c:Date) => {this.STATE.currentTime=c; this.callback('set_current_time',c);}
    SetPlayMode = (c:string) => {
        this.STATE.playMode=c; 
        this.callback('set_playmode',c);
        
        if(c==='M' && this.MONEY_MODE_URL) { 
            this.SendClientEvent('MovetoMoney'); 
            this.SendClientEvent('ReloadGame');
            if(window.top && window.top.location) {
                window.top.location.href = this.MONEY_MODE_URL;
            }
            else {
                window.location.href = this.MONEY_MODE_URL;
            }
        } else if(c==='M' && this.FRONT_END?.code==="PARIPLAY") { 
            this.SendClientEvent('MovetoMoney'); 
            this.SendClientEvent('ReloadGame');
        } else if(c==='M' && this.FRONT_END?.code==="NEOGAMES") { 
            this.SendClientEvent('MovetoMoney'); 
        } else {
            let loc = window.location.href;
            loc = loc.replace(/&playMode=[A-Z]/g,'');
            loc = loc + '&playMode=' + c;
            window.history.pushState("Switching Mode", 'DTB', loc);
            this.Initialize(true);
        }
    }

    ShowInfo = (c:EQLInfoMessage) => {
      this.callback('show_info',c);
    }
    
    ShowBonus = (c:EQLInfoMessage) => {
        this.callback('show_bonus',c);
    }

    ShowEndBonus = (c:EQLInfoMessage) => {
        this.callback('show_end_bonus',c);
    }

    SendClientEvent = (eventName:string, data?: any) => {
        this.FRONT_END?.SendClientEvent(eventName, this.GAME_ID, this.STATE.languageCode, data);
    }

    ToggleSound = () => {
      this.SendClientEvent('setVolume');
      this.SendClientEvent('SetVolume', this.STATE.sound?0:1);
      this.STATE.sound=!this.STATE.sound; 

      if(!this.STATE.sound) {
        Howler.volume(0);
      } else {
        Howler.volume(this.STATE.settings.volume);
      }

      this.callback('set_sound',this.STATE.sound);
    }

    SetWrapperSize = (size: Dimensions) => {
        this.STATE.wrapperSize = {
            width: size.width,
            height: size.height
        };
    }
    SetDisplaySize =  (size: Dimensions) => {
        this.STATE.displaySize = {
            width: size.width,
            height: size.height
        };
    }

    UpdateSize = () => {
        this.callback("update_size", null)
    }
}

export default EQL;
